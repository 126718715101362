/* =====================================
 * NORMALIZE
 * ================================== */
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; } // good to have for horizontal scroll prevention

html {
  overflow-y: scroll; } // prevents page jump and page scroll when opening e.g.dialog-box

address {
  font-style: normal; }

/* =====================================
 * GOOGLE FONT IMPORT
 * ================================== */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600&display=swap');
// TODO: take out line below
// @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600&family=Poppins:wght@200;300;400;500;600&family=Spartan:wght@200;300;400;500;600&display=swap')

@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Round");

/* =====================================
 * SASS VARIABLES
 * ================================== */
$font: 'Nunito', sans-serif;

$black: #000000;
$gray-dark: #666666; // txt on gray
$gray-normal: #999999; // txt on white
$gray-light: #F2F2F2; // backgrounds
// $gray-button: #EFEFEF   //button-background
// $blue-light: #00E7E6
// $blue-light: #3DADFF
$blue-normal: #00AFDB;
$blue-light: #00CCFF;
$blue-light-desat: #80E5FF;
// $blue-light: #52A3FF
$white: #FFFFFF;

$red: #dc3545;
$admin-blue: #007bff;
$admin-blue-border: #80BDFF;

$content-margin: .86em;
$content-margin-narrow: 1.3em;

/* =====================================
 * GENERAL
 * ================================== */
html {
  font-size: 14px;
  color: $black; }

@media(min-width: 769px) {
  html {
    font-size: 18px; } }

%body-shared {
  font-family: $font;
  font-size: 1rem;
  line-height: 1.66;
  font-weight: 300;
  overflow-x: hidden; } // good to have for horizontal scroll prevention

body {
  @extend %body-shared;
  position: relative; } // good to have for horizontal scroll prevention

img {
  max-width: 100%;
  display: block;
  height: auto; } // for ie

table {
  border-collapse: collapse;
  border-spacing: 0; }

// hero image
.heroImage {
  margin-bottom: 2rem; }

.heroImage-container {
  width: 100%;
  // padding-bottom: 50%
  padding-bottom: 56%;
  position: relative; }

.heroImage-wrap {
  width: 100%;
  height: 100%;
  position: absolute; }

.heroImage-wrap {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // below three lines for skeleton loading
    position: relative;
    z-index: 2;
    transition: 1s; } }

.bannerText-main {
  width: 100%;
  text-align: center; }

/* =====================================
 * UTILITIES
 * ================================== */
ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer; }

.hide {
  display: none !important; } // important need to override navBurger display:flex on membership page

.hideFade {
  opacity: 0; }

.clearfix::after {
  content: " ";
  display: block;
  height: 0;
  clear: both; }

.noscroll {
  position: fixed;
  overflow-y: scroll;
  width: 100%; }

.relative {
  position: relative; }

.no-click {
  pointer-events: none; }

.hover {
  color: $blue-light; }

/* =====================================
 * TYPOGRAPHY
 * ================================== */
%h1-to-h4-styles {
  font-family: $font;
  font-weight: 600;
  line-height: 1.4; }

h1, h2, h3, h4, .infoText {
  @extend %h1-to-h4-styles; }

h1 {
  font-size: 1.9rem;
  margin: .67em 0;
  line-height: 1.15; }

h2 {
  font-size: 1.43rem;
  margin: 1.1em 0; }

h3 {
  font-size: 1.1rem;
  margin: .5em 0; }

h4, .infoText {
  font-size: .89rem;
  margin: 0 0 1.07em; }

.infoText-landing {
  margin-left: 1.1rem;
  margin-top: .556rem;
  word-spacing: .166667rem;
  font-size: .91rem; }

.h1-landing {
  margin-top: 0;
  letter-spacing: 0.07222rem; }

@media(min-width: 769px) {
  .h1-landing {
    font-size: 2.22rem; } }

.h1-cartLarge {
  font-size: 1.5rem;
  margin: .24em 0;
  text-transform: uppercase;
  transition: .2s; }

.h3-cartLarge {
  @extend %body-shared;
  color: $gray-normal;
  text-transform: uppercase;
  margin: 0;
  margin-top: 1em;
  line-height: 1.4; }

.h1-cartSmall {
  @extend %body-shared;
  font-weight: 600;
  margin: 0; }

.h3-cartSmall {
  font-size: 12px;
  line-height: 1.2;
  font-size: .75em;
  text-transform: uppercase;
  color: $gray-normal;
  margin: 0;
  margin-top: .5em;
  font-weight: 300; }

.h1-extraLarge {
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: .03em;
  font-size: 2.22rem;
  line-height: 1.2; }

.h1-caps {
  text-transform: uppercase;
  letter-spacing: .088em; }

p {
  font-family: $font;
  margin: .36em 0 1em; }

.p-cartSmall {
  font-size: .9rem;
  line-height: 1.3;
  margin: .8em 0 1em; }

a {
  font-family: $font;
  font-weight: 600;
  color: $blue-light;
 }  // transition: color .2s ease-in-out

// a:hover, a:focus
//   color: #a786df

// consider link reset class
.a-reset {
  font-weight: inherit;
  color: inherit; }

.smallText {
  font-size: 12.5px;
  margin-top: .34em;
  line-height: 1.2;
  display: inline-block; }

@media (min-width: 370px) {
  .smallText {
    font-size: 14px; } }

/* =====================================
 * SITEWIDE
 * ================================== */

/* || LAYOUT */

.margin-container {
  margin: 0 auto;
  padding: 0 $content-margin;
  max-width: 1417px; }

.margin-container-sm {
  margin: 0 auto;
  padding: 0 $content-margin-narrow;
  max-width: 1200px; }

.margin-container-narrow {
  margin: 0 auto;
  padding: 0 $content-margin-narrow;
  max-width: 950px; }

@media (max-width: 355px) {
  .margin-container-none {
    margin: 0;
    padding: 0; } }

@media (min-width: 769px) {
  .margin-container-sm {
    padding: 0 2.2em; } }

.topMargin-container {
  padding-top: 2rem; }

.bottomMargin-container {
  margin-bottom: 3.5rem; }

/* || HEADER */
header {
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 8px;

  position: fixed;
  top: 0;
  z-index: 9;
  background-color: $white;

  .logged-in {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    span, a {
      margin-right: .9rem; }
    a {
      color: $red; } }

  .margin-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }

  .logo {
    width: 160px; }

  .logo-small {
    width: 260px; } }

@media (min-width: 340px) {
  header {
    padding-bottom: 10px;

    .logo {
      width: 210px; } } }

@media (min-width: 550px) {
  header {
    .logo {
      width: 250px; } } }

@media (min-width: 1300px) {
  header {
    .logo {
      width: 350px; } } }

/* || FOOTER */
footer {
  margin-top: 100px; }

.footer-sm {
  margin-top: 40px; }

.footer-main {
  font-size: 12px;
  font-weight: 600;
  padding-top: 25px;
  background-color: $gray-light;
 }  // margin-bottom: 20px

@media (min-width: 499px) {
  .footer-main {
    font-size: 14px; } }

@media (min-width: 769px) {
  .footer-main-topRow {
    display: flex; } }

.footer-main-header {
  padding-bottom: 12px;
  border-bottom: 1px solid $black;

  h4 {
    font-size: 12px;
    text-transform: uppercase;
    margin: 0; } }

@media (min-width: 499px) {
  .footer-main {
    h4 {
      font-size: 14px; } } }

@media (min-width: 769px) {
  .footer-main {
    h4 {
      padding-top: 23px; } } }

.footer-main-content-hours {
  margin-bottom: 20px; }

.footer-main-hours {
  margin: 15px 0;
  width: 100%;
  border: none;
  padding: 0;

  tr {
    background: none; }

  tr:nth-child(odd) {
    background: $white; }

  td {
    padding: 5px 12px 7px; } }

.footer-logo {
  font-size: 1.1rem;
  text-transform: uppercase;
  color: $black;
  padding-top: 10px;
  display: inline-block; }

@media (min-width: 769px) {
  .footer-main-column-hours {
    order: 2;
    margin-left: 12px;
    width: 50%; }

  .footer-main-column-mainContent {
    width: 50%; } }

.footer-main-content-mainContent {
  padding-bottom: 1.5em; }

.footer-main-content-mainContent-row {
  display: flex; }

.footer-main-column-contact {
  width: 50%;
  line-height: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    margin: 0;
    padding: 15px 0 22px; } }

.footer-main-column-menu {
  margin-left: .86em;
  width: calc(50% - .86em);
  margin-left: 2em; // TODO: possibly change back to .86
  width: calc(50% - 2em);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ul {
    margin: 15px 0 25px;
    padding: 0;
    line-height: 1.3;

    li {
      margin: 0;
      padding: 5px 0 8px;
      page-break-inside: avoid;
      break-inside: avoid;

      a {
        display: inline-block;
        margin: inherit;
        color: inherit; } } } }

@media (min-width: 1160px) {
  .footer-main-column-menu {
    ul {
      columns: 2;

      li {
        page-break-inside: avoid;
        break-inside: avoid; } } } }

.footer-main-bottomRow {
  padding-bottom: 20px; }

@media (min-width: 320px) {
  .footer-main-bottomRow {
    padding-bottom: 0; } }

.footer-social {
  padding-bottom: 28px;

  a {
    display: block;
    background: $black;
    height: 26px;
    width: 26px;
    line-height: 26px;
    color: white;
    border-radius: 50%;
    font-size: 16px;
    // margin: 20px
    transition: 200ms;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 13px 0 0;
    float: left;
    margin-top: 11px; }

  a:hover {
    background: $blue-light; } }

.footer-bottom {
  margin: 13px 0;
  font-size: 14px; }

// /* || FORMS */
.crud-form {
  background-color: $gray-light;
  padding-bottom: 60px; }

.crud-form-col {
  max-width: 540px;

  h1 {
    padding: 40px 0; } }

.input-wrap {   // also in loginForm
  margin-top: 1.3em;
  margin-bottom: 1.7em;
  padding: 2rem;
  background-color: $white;
  border-radius: 4px; }

.input-wrap-less-top-padding {
  padding-top: .5rem; }

.img-upload {
  display: flex;
  align-items: center;

  .img-upload-button {
    margin-right: 30px; }

  .image-preview {
    width: 300px;
    height: 170px;
    overflow: hidden;
    // border: 2px solid $gray-light
    background: $gray-light;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } } }

/* || COMPONENTS */

// nav
nav {
  display: none; }

@media (min-width: 1055px) {
  // Main nav
  nav {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    align-items: flex-end;
    padding-bottom: 6px;

    ul {
      display: flex;
      flex-grow: 1;
      justify-content: center; }

    li {
      margin-right: 40px;

      a:hover {
        color: $blue-normal; } }

    li:last-child {
      margin-right: 0; }

    a {
      font-size: 13.5px;
      letter-spacing: .5px;
      letter-spacing: .252em;
      color: $black;
      text-transform: uppercase;
      transition: color .15s ease-in-out; }

    .nav-join {
      border: 1px solid $blue-light;
      padding: 1.07em 3.21em; //16px 48px
      margin-bottom: 8px;
      transition: background .3s;
      letter-spacing: .1em;
      padding: 16px 24px; }

    .nav-join:hover {
      background-color: $blue-light; } } }

.active {
  // border-bottom: .5px solid $blue-light
  // padding-bottom: 4px
  color: $blue-normal; }

@media (min-width: 1300px) {
  nav {
    padding-bottom: 10px;

    li {
      margin-right: 60px; }

    a {
      font-size: 15px; } } }

// NavBurger
.navBurger-container {
  width: 46px;
  height: 46px;
  background-color: rgba(0, 0, 0, 0);

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer; }

.navBurger-container:hover {
  background-color: rgba(0, 0, 0, 0.05); }

.navBurger-container.burgerActive {
  background-color: rgba(0, 0, 0, 0.05); }

.navBurger {
  display: inline-block; }

.navBurger-line {
  width: 29px;
  height: 2px;
  background-color: #000000;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out; }

.navBurger-line:nth-child(1) {
  margin-bottom: 7px; }

.navBurger-line:nth-child(2) {
  margin-bottom: 7px; }

.navBurger-line:nth-child(3) {
  margin-top: 7px; }

.navBurger.burgerActive .navBurger-line:nth-child(2) {
  opacity: 0; }

.navBurger.burgerActive .navBurger-line:nth-child(1) {
  -webkit-transform: translateY(9px) rotate(45deg);
  -ms-transform: translateY(9px) rotate(45deg);
  -o-transform: translateY(9px) rotate(45deg);
  transform: translateY(9px) rotate(45deg); }

.navBurger.burgerActive .navBurger-line:nth-child(3) {
  -webkit-transform: translateY(-9px) rotate(-45deg);
  -ms-transform: translateY(-9px) rotate(-45deg);
  -o-transform: translateY(-9px) rotate(-45deg);
  transform: translateY(-9px) rotate(-45deg); }

@media (min-width: 1055px) {
  .navBurger-container {
    display: none; } }

// nav-mobile
.navMobile {
  // background-color: #f2f2f2
  background-color: $white;
  position: fixed;
  // top: 63px
  bottom: 0;
  left: -100%;
  width: 100%;
  transition: transform 0.2s ease-in-out;
  z-index: 9;

  .navMobile-margin-container {
    margin-top: 50px;
    padding: 0 .86rem;

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

  li {
    display: block;
    padding: 12px;
    padding: 15px;
    border-top: 2px solid $blue-light; }

  a {
    font-size: 18px;
    letter-spacing: .5px;
    letter-spacing: .252em;
    color: $black;
    text-transform: uppercase;
    display: block; }

  .navMobile-btn {
    margin-bottom: 80px; } } // at least 50px needed for margin-top adjustment of margin-container

.navMobile-active {
  transform: translateX(100%); }

// @media (min-width: 340px)
//   .navMobile
//     top: 90px

// @media (min-width: 550px)
//   .navMobile
//     top: 118px

// @media (min-width: 1055px)
//   .navMobile
//     display: none

// home
.landing-sideInfo {
  display: flex; }

@media(min-width: 769px) {
  .landing-sideInfo {
    width: 28%; } }

.arrow-landing {
  font-size: 2.222rem;
  transition: .2s; }

// featuredCart
.featuredCart, .bannerText {
  a {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    margin: inherit;
    color: inherit; } }

.featuredCart-gridItem-info {
  br {
    display: none; } }

@media (min-width: 1280px) {
  .featuredCart-gridItem-info {
    br {
      display: inline; }

    span {
      display: none; } } }

.featuredCart-gridItem-link {
  color: $blue-light; }

// aboutCart
.aboutCart-sideInfo {
  border-top: solid 6px $blue-light;
  padding: 15px 30px;
  // flex-grow: 1

  .aboutCart-sideInfo-hours {
    margin-bottom: 18px; } }

@media (min-width: 769px) {
  .aboutCart-row {
    display: flex; }

  .aboutCart-p {
    width: 64%;
    margin-right: 50px; }

  .aboutCart-sideInfo {
    width: 28%; } }

// locationMap
.gettingHere-iframe {
  width: 100%;
  height: 418px; }

@media (min-width: 769px) {
  .gettingHere-row {
    display: flex; }

  .gettingHere-iframe-wrap, .gettingHere-info-wrap {
    width: 50%;
    padding: 10px; }

  .gettingHere-info-wrap {
    padding-top: 20px; } }

// currentShow
.currentShow-p {
  max-width: 756px; }

@media (min-width: 769px) {
  .currentShow-gridThreeCol {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 17px;
    row-gap: 20px; } }

@media (min-width: 900px) {
  .currentShow-gridThreeCol {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px; } }

// featuredCartSm
.featuredCartSm {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 15px 0; }

.featuredCartSm-row {
  display: flex; }

.featuredCartSm-col1 {
  display: flex;
  align-items: center; }

.featuredCartSm-imgContainer {
  position: relative;
  width: 80px;
  height: 80px; }

@media (min-width: 370px) {
  .featuredCartSm-imgContainer {
    width: 120px;
    height: 120px; } }

@media (min-width: 420px) {
  .featuredCartSm-imgContainer {
    width: 150px;
    height: 150px; } }

.featuredCartSm-imgWrap {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // below three lines for skeleton loading
    position: relative;
    z-index: 2;
    transition: 1s; } }

@media (min-width: 769px) {
  .featuredCartSm-row {
    flex-direction: column; }

  .featuredCartSm-imgContainer {
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: 100%; }

  .featuredCartSm-imgWrap {
    position: absolute;
    width: 100%;
    height: 100%; } }

.featuredCartSm-col2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center; }

.featuredCartSm-name {
  margin-top: 5px; }

.featuredCartSm-p {
  height: 80px;
  overflow: hidden;
  margin-left: 6px;
  margin-right: 6px; }

.featuredCartSm-more {
  margin-top: auto;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 3px;

  a {
    color: inherit; } }

.featuredCartSm-more-text {
  font-size: 11px;
  background-color: $blue-light-desat;
  padding: 5px 8px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25); }

@media (min-width: 320px) {
  .featuredCartSm-more-text {
    font-size: .89rem;
    background-color: $blue-light-desat;
    padding: 5px 15px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25); } }

@media (min-width: 769px) {
  .featuredCartSm-col2 {
    align-items: inherit; }

  .featuredCartSm-name {
    margin: 8px 8px 0; }

  .featuredCartSm-p {
    height: 100px;
    margin-left: 8px;
    margin-right: 8px; }

  .featuredCartSm-more {
    height: 50px;
    line-height: 50px;
    margin-bottom: 5px; } }

// artists
.artists-gridSixCol {
  padding-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 7px;
  row-gap: 2.3rem; }

@media (min-width: 355px) {
  .artists-gridSixCol {
    column-gap: 11px; } }

@media (min-width: 480px) {
  .artists-gridSixCol {
    grid-template-columns: 1fr 1fr 1fr; } }

@media (min-width: 690px) {
  .artists-gridSixCol {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 16px;
    row-gap: 3rem; } }

@media (min-width: 916px) {
  .artists-gridSixCol {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 22px; } }

@media (min-width: 1055px) {
  .artists-gridSixCol {
    padding-top: 40px; } }

@media (min-width: 1150px) {
  .artists-gridSixCol {
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr
    column-gap: 26px; } }

// artistTile
.artistTile-link {
  font-weight: inherit;
  margin: 0;
  color: inherit; }

.artistTile-imgContainer {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  cursor: pointer; }

.artistTile-imgWrap {
  width: 100%;
  height: 100%;
  position: absolute;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // below three lines for skeleton loading
    position: relative;
    z-index: 2;
    transition: 1s; } }

// artistIntro
.artistIntro {
  padding-top: .5rem; }

.artistIntro-name {
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto 20px;
  max-width: 320px; }

.artistIntro-imgMaxWidth {
  max-width: 320px;
  margin: 0 auto; }

.artistIntro-imgContainer {
  position: relative;
  width: 100%;
  padding-bottom: 75%; }

.artistIntro-imgWrap {
  position: absolute;
  width: 100%;
  height: 100%; }

.artistIntro-imgWrap {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // below three lines for skeleton loading
    position: relative;
    z-index: 2;
    transition: 1s; } }

@media (min-width: 499px) {
  .artistIntro-name {
    max-width: 450px;
    margin-bottom: 40px; }

  .artistIntro-imgMaxWidth {
    max-width: 450px; } }

@media (min-width: 769px) {
  .artistIntro-name {
    max-width: 500px; }

  .artistIntro-imgMaxWidth {
    max-width: 500px; } }

@media (min-width: 1055px) {
  .artistIntro {
    padding-top: 2rem; }

  .artistIntro-row {
    display: flex; }

  .artistIntro-col1 {
    width: 31%;
    display: flex;
    align-items: center;
    margin-right: 50px; }

  .artistIntro-name {
    max-width: inherit;
    margin: 0; }

  .artistIntro-col2 {
    width: 69%; }

  .artistIntro-imgMaxWidth {
    max-width: 550px;
    max-width: inherit; } }

@media (min-width: 1500px) {
  .artistIntro-col1 {
    margin-right: 80px; }

  .artistIntro-imgMaxWidth {
    max-width: inherit; } }

// artistMain
.artistMain {
  margin-top: 40px; }

.artistMain-col1 {
  p {
    white-space: pre-line; } }

.artistMain-col2 {
  margin-top: 30px; }

.artistMain-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 40px; }

@media (min-width: 355px) {
  .artistMain-col2 {
    margin-top: 40px; }

  .artistMain-grid {
    grid-template-columns: 1fr 1fr;
    column-gap: 20px; } }

@media (min-width: 769px) {
  .artistMain {
    margin-top: 55px; }

  .artistMain-col2 {
    margin-top: 55px; }

  .artistMain-grid {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px; } }

@media (min-width: 1055px) {
  .artistMain {
    margin-top: 100px; }

  .artistMain-row {
    display: flex; }

  .artistMain-col1 {
    width: 31%;
    margin-right: 50px;

    p {
      margin-top: 0; } }

  .artistMain-col2 {
    width: 69%;
    margin: 0; } }

@media (min-width: 1500px) {
  .artistMain-col1 {
    margin-right: 80px; } }

// events
.calendar-monthView {
  display: none; }

.calendar-container {
  position: relative;
  width: 100%;
  padding-bottom: 140%; }

.calendar-wrap {
  position: absolute;
  width: 100%;
  height: 100%; }

.calendar {
  width: 100%;
  height: 100%;
  // below three lines for skeleton loading
  position: relative;
  z-index: 2;
  transition: 1s; }

@media (min-width: 300px) {
  .calendar-container {
    padding-bottom: 100%; } }

@media (min-width: 355px) {
  .calendar-container {
    padding-bottom: 120%; } }

@media (min-width: 420px) {
  .calendar-container {
    padding-bottom: 100%; } }

@media (min-width: 550px) {
  .calendar-agendaView {
    display: none; }

  .calendar-monthView {
    display: block; } }

@media (min-width: 769px) {
  .calendar-container {
    padding-bottom: 75%; } }

@media (min-width: 1055px) {
  .calendar-container {
    padding-bottom: 60%; } }

.studioTour-map {
  width: 100%;
  height: 480px; }

@media (min-width: 769px) {
  .studioTour-map {
    height: 750px; } }

// about
.boardOfDirectors {
  margin-top: 5rem; } //70px

.boardOfDirectors-grid {
  margin-top: 30px;
  display: grid;
  row-gap: 20px; }

@media (min-width: 359px) {
  .boardOfDirectors-grid {
    margin-top: 40px; } }

@media (min-width: 595px) {
  .boardOfDirectors-grid {
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    row-gap: 30px; } }

@media (min-width: 1170px) {
  .boardOfDirectors-grid {
    margin-top: 50px; }

  .boardOfDirectors-grid {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 50px;
    row-gap: 50px; } }

.introCart-topRow {
  display: flex;
  justify-content: space-between; }

.introCart-nameTitle {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  // margin-right: 5px
  margin: 0 auto; }

.introCart-img {
  margin-left: 5px; }

.introCart-img-container {
  width: 130px;
  padding-bottom: 100%;
  position: relative; }

.introCart-img-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden; }

.introCart-img-wrap {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    // below three lines for skeleton loading
    position: relative;
    z-index: 2;
    transition: 1s; } }

// .introCart-p
//   font-size: 13px
//   margin-top: 12px

@media (min-width: 359px) {
  .introCart-img {
    margin-left: 15px; } }

@media (min-width: 390px) {
  .introCart-img-container {
    width: 150px; }

  // .introCart-p
  //   font-size: 14px
 }  //   margin-top: 12px

// artAroundTown
.artAroundTown {
  p {
    max-width: 756px; } }

// artAroundTownInfo
@media (min-width: 390px) {
  .artAroundTownInfo-row {
    display: flex; }

  .artAroundTownInfo-hours {
    margin-left: 18px; } }

@media (min-width: 450px) {
  .artAroundTownInfo-hours {
    margin-left: 50px; } }

// infoCartImgRight
.infoCartImgRight {
  margin-top: 3rem; }

.infoCartImgRight-row {
  margin-bottom: 50px; }

.infoCartImgRight-img-container {
  width: 100%;
  padding-bottom: 56%;
  position: relative; }

.infoCartImgRight-img-wrap {
  width: 100%;
  height: 100%;
  position: absolute; }

.infoCartImgRight-img-wrap {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // below three lines for skeleton loading
    position: relative;
    z-index: 2;
    transition: 1s; } }

.infoCartImgRight-info {
  padding-top: 1.5rem; }

@media (min-width: 769px) {
  .infoCartImgRight-row {
    margin-bottom: 100px; } }

@media (min-width: 1055px) {
  .infoCartImgRight-row {
    display: flex; }

  .infoCartImgRight-img {
    width: 50%;
    order: 2; }

  .infoCartImgRight-info {
    width: 50%;
    padding-top: .36rem;
    margin-right: 20px; } }

// joinUs
.joinUs-grid {
  display: grid;
  row-gap: 50px; }

@media (min-width: 595px) {
  .joinUs-grid-2 {
    grid-template-columns: 1fr 1fr;
    column-gap: 30px; }

  .joinUsColumn:nth-child(3) {
    grid-column: span 2; } }

@media (min-width: 1070px) {
  .joinUs-grid-3 {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px; }

  .joinUsColumn:nth-child(3) {
    grid-column: span 1; } }

// joinUsColumn
.joinUsColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.joinUsColumn-heading {
  text-align: center; }

.joinUsColumn-bulletList {
  max-width: 300px;
  margin: 0 auto;

  li {
    display: flex;
    margin: 15px 0; }

  i {
    font-size: 15px;
    color: $blue-light; } }

.joinUsColumn-circle {
  margin: 0 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.joinUsColumn-text {
  margin-right: auto;
  // max-width: 180px
  text-transform: uppercase;
  font-size: 16px; }

.joinUsColumn-btn {
  text-align: center;
  background-color: $gray-light;
  padding: 10px 0;

  a, .joinUs-submit {
    font-size: 17px;
    // font-size: 20px
    letter-spacing: .5px;
    letter-spacing: 2px;
    color: $black;
    // color: $blue-light
    text-transform: uppercase;
    border: 1px solid $blue-light-desat;
    padding: .768em 1.6em .64em;
    transition: background .2s;
    display: inline-block;
    margin: 0 auto;
    background-color: $blue-light-desat;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); }

  .joinUs-submit {
    font-family: $font;
    font-weight: 600;
    line-height: 1.66;
    cursor: pointer; }

  a:hover, .joinUs-submit:hover {
    background-color: $blue-light; } }

@media (min-width: 769px) {
  .joinUsColumn-text {
    font-size: 18px; }

  .joinUsColumn-btn {
    padding: 16px 0;

    a, .joinUs-submit {
      font-size: 20px; } } }

// ADMIN STYLES
.admin-btn {
  font-size: 14px !important;
  font-family: Helvetica, sans-serif !important;
  font-weight: 500 !important;
  color: white !important;
  background: #e63a42 !important;
  background: #007bff !important;
  padding: 5px 9px !important;
  border: 3px solid #E69195 !important;
  border: 3px solid #80BDFF !important;
  border-radius: 5px !important;
  margin: 5px !important;
  display: inline-block !important;
  cursor: pointer;
  text-align: center;
  min-width: 55px; }

.admin-btn-create {
  min-height: 35px;
  line-height: 35px;
  min-width: 120px; }

.admin-btn-delete {
  background: $red !important;
  border: 3px solid #E69195 !important; }

.imgInputWrap {
  display: flex;
  align-items: flex-start;

  img {
    width: 100px;
    height: 100px; } }

// featuredCarts
@media (min-width: 767px) {
  .featuredCarts-grid-2-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 13px;
    row-gap: 20px; } }

// featuredCart
.featuredCart-imgContainer {
  position: relative;
  width: 100%;
  padding-bottom: 56.2%;
  margin: 0 auto; }

.featuredCart-imgWrap {
  position: absolute;
  width: 100%;
  height: 100%; }

.featuredCart-imgWrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  // below three lines for skeleton loading
  position: relative;
  z-index: 2;
  transition: 1s; }

.featuredCart-grid {
  padding: 0 12px; }

@media (min-width: 1280px) {
  .featuredCart-grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto auto auto 1fr;
    column-gap: 12px;
    row-gap: 0px; }

  .featuredCart-grid-noSideCol {
    grid-template-columns: 1fr; }

  .featuredCart-gridItem {
    grid-column: 1; }

  .featuredCart-gridItem-info {
    grid-column: 2;
    grid-row: 2/5; } }

// contactCart
.contactCart-social {
  display: flex;
  margin-bottom: 2em;

  a {
    display: block;
    background: $gray-dark;
    height: 30px;
    width: 30px;
    line-height: 30px;
    color: white;
    border-radius: 50%;
    font-size: 18.5px;
    // margin: 20px
    transition: 200ms;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 20px 0 0;
    float: left; }

  a:hover {
    background: $blue-light; } }

// membership
.membership {
  background-color: $gray-light; }

.membership-container {
  padding-top: 50px;
  padding-bottom: 50px; }

.membership-form-col {
  // max-width: 400px
  max-width: 420px; }

@media (min-width: 769px) {
  .membership-form-col {
    // max-width: 570px
    // max-width: 530px
    max-width: 540px; } }

// textInputsMemberProfile
.textInputWrapperMemberProfile {
  margin-top: 1em;
  padding: 1em;
  border: 1px solid $red;

  h4 {
    color: $red;
    margin: 0; } }

// membershipOrder

.orderSummary-membership {
  display: flex;
  justify-content: space-between;

  span {
    text-transform: capitalize; } }

.orderSummary-total {
  display: flex;
  justify-content: space-between;
  border-top: .5px solid $gray-normal;
  margin-top: 1em;
  padding-top: 1em; }

.payPalBtns-wrap {
  margin-top: 1.3em;
  margin-bottom: 1.7em;
  // max-width: 458px
  // margin-right: auto
 }  // margin-left: auto

// loginForm
.login {
  background-color: $gray-light; }

.login-container {
  padding-top: 50px;
  padding-bottom: 50px; }

.login-form-col {
  // max-width: 400px
  max-width: 420px; }

@media (min-width: 769px) {
  .login-form-col {
    // max-width: 570px
    // max-width: 530px
    max-width: 540px; } }

// subscribeDialogBox
.subscribe {
  border: 1px solid $black;
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer; }

@media (min-width: 499px) {
  .subscribe {
    margin-top: .5em; } }

@media (min-width: 769px) {
  .subscribe {
    max-width: 436px; } }

.subscribe-text {
  padding: .6em .9333em; }

.subscribe-arrow-wrap {
  background-color: $white;
  display: flex;
  align-items: center;
  padding: 0 .72em; }

.subscribe-arrow {
  font-size: 1.5rem;
  transition: .2s; }

.subscribe-thanks {
  cursor: pointer;

 }  // padding-top: .5em

.subscribe-close-wrap {
  text-align: right;
  padding: .5em .5em 0 0; }

.subscribe-close {
  font-size: 1.8rem;
  transition: .2s;
  margin: {} }

.subscribe-thanks-msg {
  padding: 1.5em; }


// topBar payPal Btn
.joinUsColumn-btn__topBar {
  padding: 16px;
  .joinUs-submit__topBar {
    font-size: 11px; } }

@media (min-width: 340px) {
  .joinUsColumn-btn__topBar {
    .joinUs-submit__topBar {
      font-size: 13px; } } }

@media (min-width: 769px) {
  .joinUsColumn-btn__topBar {
    .joinUs-submit__topBar {
      font-size: 20px; } } }

.mural-section {
  margin-bottom: 50px; }

@media (min-width: 769px) {
  .mural-section {
    margin-bottom: 100px; } }

// Image Slider
.media-scroller {
  display: grid;
  gap: 3px;
  grid-auto-flow: column;
  grid-auto-columns: 70%;
  // padding: 0 5px 5px
  overflow-x: auto;
  overscroll-behavior-inline: contain;
 }  // background-color: #77DAF2;

.media-element {
  display: grid;
  grid-template-rows: min-content;
  gap: 3px;
  padding: 3px;
  border-radius: 2px;
  // background-color: #e0e0e0
  // border: 1px solid red
 }  // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px

.media-element > img {
  inline-size: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  cursor: pointer; }

.snaps-inline {
  scroll-snap-type: inline mandatory;
  scroll-padding-inline: 3px; }

.snaps-inline > * {
  scroll-snap-align: start; }

@media (min-width: 450px) {
  .media-scroller {
    gap: 5px;
    grid-auto-columns: 40%; }

  .media-element {
    gap: 5px;
    padding: 5px; } }

@media (min-width: 769px) {
  .media-scroller {
    gap: 8px; }

  .media-element {
    gap: 8px;
    padding: 8px; } }
